import React from 'react';

const MapMarker = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 46.412 46.412"
      width="30"
      height="30"
    >
      <path
        d="M39.652 16.446C39.652 7.363 32.289 0 23.206 0 14.124 0 6.761 7.363 6.761 16.446c0 1.775.285 3.484.806 5.086 0 0 1.384 6.212 15.536 24.742 8.103-10.611 12.018-17.178 13.885-20.857a16.377 16.377 0 002.664-8.971zM23.024 27.044c-5.752 0-10.416-4.663-10.416-10.416 0-5.752 4.664-10.415 10.416-10.415S33.44 10.876 33.44 16.628c-.001 5.753-4.664 10.416-10.416 10.416zM23.206 46.412l-.105-.139-.106.139h.211z"
        fill="#38417c"
      />
    </svg>
  );
};

export default MapMarker;
